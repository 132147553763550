;(function($) {
/************************************************************
* INIT
************************************************************/
function init() {
	
	/************************************************************
	SET UP THE BASICS:
	************************************************************/
	// Load PrettyPhoto (Lightbox)
	//$("a[rel^='prettyPhoto']").prettyPhoto();
	
	// For ie10 Selection => reflected in css/browsers.css
	var b = document.documentElement;
	b.setAttribute('data-useragent',  navigator.userAgent);
	b.setAttribute('data-platform', navigator.platform );
	
	// IE8 Bug Fix for fontawesome menu icon
	if($('html').hasClass('lt-ie9')) {
			var head = document.getElementsByTagName('head')[0],
			style = document.createElement('style');
		style.type = 'text/css';
		style.styleSheet.cssText = ':before,:after{content:none !important';
		head.appendChild(style);
		setTimeout(function(){
			head.removeChild(style);
		}, 0);
	}
	
	// Initiaite Browser Detect from functions.js:
	BrowserDetect.init();
	// document.write("You are using <b>" + BrowserDetect.browser + "</b> with version <b>" + BrowserDetect.version + "</b>");
	// identity: "Chrome", "Explorer", "Firefox", "Safari", "Opera" 
	// ex: if(BrowserDetect.browser == 'Firefox') { //do something... }
	
	/************************************************************
	BUILT IN SCRIPTS:
	************************************************************/
	// ANIMATE SOMETHING BASED ON URL HASH
	var hash = window.location.hash;
	/* if(hash == '#div-id') { 
			// do something
		}
	*/
	
	// PLACEHOLDER BEHAVIOR FOR FORMS
	// Reference: http://mathiasbynens.github.io/jquery-placeholder
	//$('input, textarea').placeholder();
	
	// Add bootstrap's clearfix classes if div has class of "clear"
	$('.clear').each(function(i,e){
		
		$(e).addClass('clearfix visible-xs-block');
		
	});
	
	// Mobile Menu
	$('.has-mobile-menu').each(function(i,e){
		
		// Build the menu
		$(this).wrapInner('<div class="menu-wrap"></div>');
		$(this).prepend('<a href="#" class="menu-button"><i class="fa fa-bars"></i></a>');
		
		if($(this).hasClass('center-vertically')) {
			$(this).find('.menu-wrap ul:first').addClass('centered-menu');
		}
		
	});
	
	// Open/Close Mobile Menu
	$('a.menu-button').on({
		click: function(){
			$(this).toggleClass('open');
			$('body,html').toggleClass('noScroll');
									
			var ul = $(this).parent('nav').find('.menu-wrap ul');		
			
			var centerUl = false;
			if(ul.hasClass('centered-menu')) { 
				centerUl = true;
			}
			
			if($(this).hasClass('open')) {
				$(this).html('<i class="fa fa-times"></i>');
				if(centerUl == true) {
					var ulHeight = ul.actual('height');
					ul.height(ulHeight).addClass('center');
				}
				$('.menu-wrap').fadeIn();
			} else {
				$(this).html('<i class="fa fa-bars"></i>');
				$('.menu-wrap').fadeOut(function(){
					ul.removeClass('center').css({ height:'auto' });
				});
				
			}
			
			return false;
		}
	});
	
	/************************************************************
	CUSTOM FUCNTIONS:
	************************************************************/
	// Banner Animations
	var h=$(".banner-wrap .active img").height();
	$(".banner-wrap").animate({height:h}, function(){
		if($(this).hasClass('fixed')) {
			$(this).find('.banner img').addClass('affix');
		}
	});
	
	// contact form
	if($('.contact-form').length){
		$('.contact-form').css({ 'visibility':'visible', opacity:0 });
		var ch = $(window).height();
		var fh = $('.contact-form').height();
		
		var tPos = (ch-fh)/2;
		
		$('.contact-form').animate({ opacity:1, top:tPos });
	}
	
	$('#show-all').on({
		click: function(){
			
			$(this).fadeOut('fast');
			$('.show-all-wrap i').css({ 'visibility':'visible' });
			$('.zoomwall.active').stop().animate({ opacity:'.5', backgroundColor:'#ddd' }, function(){
				
				$(this).append('<div class="temp-wrap"></div>');
				var t = $(this).html();
				var offset = $(this).attr('data-offset');
				var count = $(this).attr('data-count');
				var name = $(this).attr('data-id');
				//var name = $(this).attr('id');
				//name = name.replace('gallery-','');
				
				offset = parseInt(offset, 10);
				//offset = offset + 1;
				
				var string = "gal_id="+name+"&offset="+offset+"&count="+count;
				
				console.log('String: '+string);
				
				$('.gallery-wrap iframe').attr('src', gallery_code_path+"?"+string);
				
				
				/*$('.zoomwall.active .temp-wrap').load(gallery_code_path+"?"+string+" .updated-gallery > *", function() {
					$('.zoomwall.active .temp-wrap').imagesLoaded().then(function(){
						$('.zoomwall.active .temp-wrap *').unwrap('.temp-wrap');
						var id = $('.zoomwall.active').attr('id');
						zoomwall.create(document.getElementById(id),true );
						zoomwall.keys();
						$('.show-all-wrap i').css({ 'visibility':'hidden' });
						$('.zoomwall.active').stop().animate({ opacity:1, backgroundColor:'transparent' }).addClass('fully-loaded');
					});
				});*/
				
			});
					
			return false;
		}
	});
		
		
	// Images Loaded
	// Fn to allow an event to fire after all images are loaded
	$.fn.imagesLoaded = function () {
	
		// get all the images (excluding those with no src attribute)
		var $imgs = this.find('img[src!=""]');
		// if there's no images, just return an already resolved promise
		if (!$imgs.length) {return $.Deferred().resolve().promise();}
	
		// for each image, add a deferred object to the array which resolves when the image is loaded (or if loading fails)
		var dfds = [];  
		$imgs.each(function(){
	
			var dfd = $.Deferred();
			dfds.push(dfd);
			var img = new Image();
			img.onload = function(){dfd.resolve();}
			img.onerror = function(){dfd.resolve();}
			img.src = this.src;
	
		});
	
		// return a master promise object which will resolve when all the deferred objects have resolved
		// IE - when all the images are loaded
		return $.when.apply($,dfds);
	
	}
	
	/************************************************************
	CUSTOM CODING:
	************************************************************/	
	$(".navwrap").hover(function(){
		$(".navwrap li").stop().animate({ top:'11px'});	
		$('header').stop().animate({ 'background-color': 'rgba(0,0,0,0.2)' }, 500);
	},function(){
		$(".navwrap li").each(function(i,e){
			if ($(e).hasClass("navham") || $(e).hasClass("navsearch")){
				//donothing
			} else {
				$(e).stop().animate({top:"-100px"});
			}
			
			$('header').stop().animate({ 'background-color': 'transparent' }, 500); 
		}); 
	});
	
	$('.navham').on('click', function(){
		
		$('#hamburger-menu').animate({ right:0 }, 500, function(){
			
			var d = 0;
			$('.menu-hamburger-menu-container li a').each(function(i,e){
				$(e).delay(d).animate({ right:0, opacity:1 }, 500);
				d=d+80;
			});
			
			d = d + 400;
			$('.widget-last').delay(d).animate({ opacity:1 }, 500);
				
		});
		
		return false;
	});
	$('#close-ham').on('click', function(){
		
		$('#hamburger-menu').animate({ right:'-150%' }, 2000, function(){
			$('.menu-hamburger-menu-container li a').css({ right:'-500px', opacity:0 });
			$('.widget-last').css({ opacity:0 });
		});
		
		return false;
	});
	
	// Team
	$('.single-team a').hover(function(){
		var img = $(this).find('img');
		var content = $(this).find('.team-content');
		
		img.stop().animate({ opacity:.3 },300);
		content.css({ 'visibility':'visible' }).stop().animate({ opacity:1, top:0 },300);
	}, function(){
		
		var img = $(this).find('img');
		var content = $(this).find('.team-content');
		
		img.stop().animate({ opacity:1 },300);
		content.stop().animate({ opacity:0, top:'45px' },300, function(){
			content.css({ 'visibility':'hidden' })
		});
		
	}).on({
		click: function(){
			return false;
		}
	});
	
	// Callouts count numbers		
	var el = $('span.count'); var w = $(window);
	if ($(el).length){
		var offset = el.offset();
				
		var pos = offset.top-w.scrollTop();
				
		//console.log(pos);
		var as = false;
		
		if ( pos <= 700) {
			el.each(function(i,e){
				$(e).prop('Counter',0).animate({
					Counter: $(e).text()
					}, {
					duration: 2000,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.ceil(now).toLocaleString('en'));
					}
				});
			});
			
			var as = true;				
		}
		$(document).scroll(function() {
			
			var el = $('span.count'); var w = $(window);
			var offset = el.offset();
			
			var pos = offset.top-w.scrollTop();
			
			if ( pos <= 700 && !as) {
				
				as = true;
				el.each(function(){
					$(this).prop('Counter',0).animate({
						Counter: $(this).text()
					}, {
						duration: 2000,
						easing: 'swing',
						step: function (now) {
							$(this).text(Math.ceil(now).toLocaleString('en'));
						}
					});
				});							
			}
		});
	}
	
	// Careers
	$('.modal').on('shown.bs.modal', function () {
 		// poulate position
		var pos_title 		= $(this).find('span#position-name').text();
		var position_input 	= $(this).find('input#position');
		
		position_input.val(pos_title);
		//console.log(pos_title);
	});
	
	$('.news-filters a').on('click', function(){
		
		var the_title = $(this).text();
		
		if($(this).parent('li').attr('id') === 'all') {
			$('div.type-post').fadeIn();
		} else {
			
			$('div.type-post').each(function(i,e){
				
				if($(e).find('p').text() === the_title) {
					$(e).fadeIn();
				} else {
					$(e).fadeOut();
				}
				
			});
			
		}
		
		return false;
	});
	
	$("a[rel^='prettyPhoto']").prettyPhoto({ deeplinking: false, social_tools: '' });

	/************************************************************
	REPSONSIVE (if needed)
	************************************************************/
	if($(window).width() === '768') { // ipad
			
	} else {
							 
	}
	
	// OR? - haven't tested this yet...
	var isiPad = navigator.userAgent.match(/iPad/i) != null;
	function isiPhone(){
		return (
			//Detect iPhone
			(navigator.platform.indexOf("iPhone") !== -1) ||
			//Detect iPod
			(navigator.platform.indexOf("iPod") !== -1)
		);
	}
	
} // end if init();

/************************************************************
* DOCUMENT READY
************************************************************/
$(function(){
	
	/************************************************************
	WINDOW RESIZE (if needed)
	************************************************************/
	var resizeId;
	$(window).resize(function(){
		
		// Reboot functions
		clearTimeout(resizeId);
    	resizeId = setTimeout(init, 200);
		
		if($(window).width() >= '768') { // ipad
			
		} else {
			
		}
		
		// IE8 Bug Fix for fontawesome menu icon
		if($('html').hasClass('lt-ie9')) {
				var head = document.getElementsByTagName('head')[0],
				style = document.createElement('style');
			style.type = 'text/css';
			style.styleSheet.cssText = ':before,:after{content:none !important';
			head.appendChild(style);
			setTimeout(function(){
				head.removeChild(style);
			}, 0);
		}
	
	});
});


/************************************************************
* ON LOAD
************************************************************/
$(window).on("load", function(){
	/************************************************************
	BUILT IN SCRIPTS:
	************************************************************/
	init();
	
	/************************************************************
	CUSTOM CODING:
	************************************************************/
	// Careers accordion
	$('#accordion').on('hidden.bs.collapse', function () {
	//do something...
	})

	$('#accordion .accordion-toggle').click(function (e){
	  var chevState = $(e.target).siblings("i.indicator").toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
	  $("i.indicator").not(chevState).removeClass("glyphicon-chevron-down").addClass("glyphicon-chevron-up");
	});

		
	//firetruck content change
	$(".wrapper.icons a").on({
		click:function(){
			
			$(".wrapper.icons a").stop().animate({ color:'#000' }, 'fast');
			$(this).stop().animate({ color:'#f00' }, 'fast');
			
			var tags = $(this).attr('rel');
			$.ajax({
				type: 'POST',
				url : ajaxurl,
				cache: false,
				data : 'action=cf7tp&tags='+tags+'&option=',
				complete : function() {  },
				success: function(data) {
					$('#blog-reel').html(data);
				}
			});
			
			var id=$(this).attr("id");
			
			$('.banner').each(function(){
				if($(this).hasClass(id)) {
					$(this).addClass('active').animate({ opacity:1 });
				} else {
					$(this).animate({ opacity:0 }).removeClass('active');
				}
			});
			
			$('.zoomwall').each(function(){
				if($(this).attr("id") === 'gallery-'+id) {
					$(this).addClass('active').animate({ opacity:1 });
					
					if($(this).hasClass('fully-loaded')) {
					} else {
						$('#show-all').fadeIn();
					}
					
					if($.trim($(this).html()).length > 0) {
						
						//console.log('not empty');
						
					} else {
					
						$(this).append('<div class="temp-wrap"></div>');
						var count = $(this).attr('data-count');
						var offset = $(this).attr('data-offset');
						var name = $(this).attr('data-id');
						//var name = $(this).attr('id');
						//name = name.replace('gallery-','');
						
						// This is when the gallery doesn't need the show all button
						if(offset === '0') { 
							offset = count; 
							$(this).addClass('fully-loaded');
							$('#show-all').fadeOut();
						}
						
						var string = "gal_id="+name+"&offset=0&count="+offset;
						
						console.log('String: '+string);
						
						$('.gallery-wrap iframe').animate({ opacity:0 }, 'fast', function(){
							$('.gallery-wrap iframe').attr('src', gallery_code_path+"?"+string);
							$('.gallery-wrap iframe').on('load', function(){ 
								$('.gallery-wrap iframe').animate({ opacity:1 });
								
								$(".gallery-wrap iframe").contents().find(".ngg-pro-mosaic-item a").click(function(){
									$('body,html').addClass('noScroll');
									$('header,footer').addClass('hide-on-click');
									$(".gallery-wrap iframe").addClass('full-screen');     
								}); 
								$(".gallery-wrap iframe").contents().find("#npl_button_close").click(function(){
									$('header,footer').removeClass('hide-on-click');
									$(".gallery-wrap iframe").removeClass('full-screen');  
									$('body,html').removeClass('noScroll');   
								}); 
								$('.gallery-wrap iframe').contents().keyup(function(e){
									 if (e.keyCode === 27) { // escape key maps to keycode `27`
										$('header,footer').removeClass('hide-on-click');
										$(".gallery-wrap iframe").removeClass('full-screen');  
										$('body,html').removeClass('noScroll');
										
									}
								});
							});
						});
						
						
						/*$('.zoomwall.active .temp-wrap').load(gallery_code_path+"?"+string+" .updated-gallery > *", function() {
							
							$('.zoomwall.active .temp-wrap').imagesLoaded().then(function(){
								$('.zoomwall.active .temp-wrap *').unwrap('.temp-wrap');
								var id = $('.zoomwall.active').attr('id');
								zoomwall.create(document.getElementById(id),true );
								zoomwall.keys();
								$('.zoomwall.active').stop().animate({ opacity:1 });
							});
						});*/
					}
					
					init();
					
				} else {
					$(this).animate({ opacity:0 }).removeClass('active');
				}
			});
			$('.description').each(function(){
				if($(this).hasClass('wrapper-'+id)) {
					$(this).addClass('active').animate({ opacity:1 });
				} else {
					$(this).animate({ opacity:0 }).removeClass('active');
				}
			});
			
			var h = $(".banner-wrap ."+id+" img").height();
			
			$('.banner-wrap').animate({height:h});
			
			return false;
			
		}
	
	});
	
	// center logos
	$('.left-logo img, .right-logo img').each(function(i,e){
		var p = $(e).parent().parent().height();
		var h = $(e).height();
		
		var tPos = (p-h)/2;
		$(e).animate({ top:tPos });
	});
	
	//matchHeight('.metrics .col-sm-3');
	
	// Timeline
	if($('.timelineLight').length) {
		$('.timelineLight').timeline({
			openTriggerClass : '.read_more',
			startItem : '01/01/1983',
			'yearsOn'                : false, 
			/*
			'categories'             : ['1982','1983','1984','1985','1986','1987','1988','1999','2000','2001','2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016'], // categories shown above timeline (months are default)
			'nuberOfSegments'        : [10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10,10], // number of elements per category (number of days)*/
		});
	}
	
	$(window).on("scroll.services", function () {
		
		// Services
		$('.service').each(function(i,e){
			
			var w = '100%';
			if($(window).width() > 767) {
				var w = '50%';
			}
			
			if(isScrolledIntoView($(e)) && !$(e).hasClass('animated')) {
				$(e).addClass('animated');
				var content = $(e).find('.service-content');
				var inner 	= $(e).find('.service-content-inner');
				
				inner.css({ 'visibility':'visible','opacity':0 });
				
				content.stop().animate({ width:'100%' }, function(){
					content.css({ left:'auto',right:0 }).stop().animate({ width:w }, function(){
						inner.stop().animate({ opacity:1 });
						inner.find('.ico-wrench').addClass('spin');
					});
				});
			}
			
		});
	});
	
	// grab an element
	var myElement = document.querySelector("header");
	// construct an instance of Headroom, passing the element
	var headroom  = new Headroom(myElement, {
	  "offset": 205,
	  "tolerance": 5,
	  "classes": {
		"initial": "animated",
		"pinned": "slideDown",
		"unpinned": "slideUp"
	  }
	});
	// initialise
	headroom.init();
	
	// to destroy
	//headroom.destroy();
	
	// Show menu on scroll up
	// http://bit.ly/2iAU6Ef
	/*var mywindow = $(window);
	var mypos = mywindow.scrollTop();
	var up = false;
	var newscroll;
	mywindow.scroll(function () {
		newscroll = mywindow.scrollTop();
		if (newscroll > mypos && !up) {
			$('header').stop().slideToggle();
			up = !up;
			console.log(up);
		} else if(newscroll < mypos && up) {
			$('header').stop().slideToggle();
			up = !up;
		}
		mypos = newscroll;
	});*/
	
});

$(window).load(function(){
	$('.gallery-wrap iframe').animate({ opacity:1 });
	
	$(".gallery-wrap iframe").contents().find(".ngg-pro-mosaic-item a").click(function(){
		$('body,html').addClass('noScroll');
		$('header,footer').addClass('hide-on-click');
		$(".gallery-wrap iframe").addClass('full-screen');     
	}); 
	$(".gallery-wrap iframe").contents().find("#npl_button_close").click(function(){
		$('header,footer').removeClass('hide-on-click');
		$(".gallery-wrap iframe").removeClass('full-screen');  
		$('body,html').removeClass('noScroll');   
	}); 
	$('.gallery-wrap iframe').contents().keyup(function(e){
		 if (e.keyCode === 27) { // escape key maps to keycode `27`
			$('header,footer').removeClass('hide-on-click');
			$(".gallery-wrap iframe").removeClass('full-screen');  
			$('body,html').removeClass('noScroll');
			
		}
	});
	
});

 

// ZOOMWALL
if($('.zoomwall').length) {
	window.onload = function() {
		$('.zoomwall.active').each(function(i,e) {
			var id = $(e).attr('id');
			zoomwall.create(document.getElementById(id),true );
		});
		zoomwall.keys();
	};
}
})(jQuery);